// Catch `fetch` events and prevent specific ones from propagating to the "real" SW".
self.addEventListener('fetch', (evt) => {
  const req = evt.request;

  if (req.url.includes('api.tag2u.com.br') || req.url.includes('api-dev.tag2u.com.br') || req.url.includes('graphql.tag2u.com.br')) {
    evt.stopImmediatePropagation();
  }
});

self.importScripts('./ngsw-worker.js');
